import {
  DashboardIndustryType,
  DashboardContractType,
  DashboardCompanyType,
  DashboardContractFilterItemsType,
  DashboardCompanyFilterItemsType,
  DsCompanyPieChartDatasType,
  DashboardIndustryFilterCorporateTypesType,
  DashboardIndustryFilterIndustry1sType,
  DashboardIndustryFilterIndustry2sType,
  DashboardIndustryFilterIndustry3sType,
  DashboardCompanyUnspecifiedIndustry,
  DashboardCompanyDownloadType,
} from 'src/type/dashboard.type'
import { httpApi } from 'src/util/http.module'

const fetchDashboardContractFilterItems = () =>
  httpApi.get<DashboardContractFilterItemsType>({ url: '/dashboard/dscontract/filteritems' })

const fetchDashboardIndustryFilterCorporateType = () =>
  httpApi.get<DashboardIndustryFilterCorporateTypesType>({ url: '/dashboard/dsindustry/corporateTypes' })

export type FetchDashboardIndustryFilterIndustry1sParamsType = {
  corporateType: string
}
const fetchDashboardIndustryFilterIndustry1s = (params: FetchDashboardIndustryFilterIndustry1sParamsType) =>
  httpApi.get<DashboardIndustryFilterIndustry1sType>({ url: '/dashboard/dsindustry/industry1s', queryParams: params })

export type FetchDashboardIndustryFilterIndustry2sParamsType = {
  corporateType: string
  industry1: string
}
const fetchDashboardIndustryFilterIndustry2s = (params: FetchDashboardIndustryFilterIndustry2sParamsType) =>
  httpApi.get<DashboardIndustryFilterIndustry2sType>({ url: '/dashboard/dsindustry/industry2s', queryParams: params })

export type FetchDashboardIndustryFilterIndustry3sParamsType = {
  corporateType: string
  industry1: string
  industry2: string
}
const fetchDashboardIndustryFilterIndustry3s = (params: FetchDashboardIndustryFilterIndustry3sParamsType) =>
  httpApi.get<DashboardIndustryFilterIndustry3sType>({ url: '/dashboard/dsindustry/industry3s', queryParams: params })

const fetchDashboardCompanyFilterItems = () =>
  httpApi.get<DashboardCompanyFilterItemsType>({ url: '/dashboard/dscompany/filteritems' })

export type FetchDashboardCompanyParamsType = {
  branchCode: string
}
const fetchDashboardCompany = (params: FetchDashboardCompanyParamsType) =>
  httpApi.get<DashboardCompanyType[]>({ url: '/dashboard/dscompany', queryParams: params })

export type FetchDashboardCompanyListParamsType = {
  branchCode: string
  page: number
  pageSize: number
}
const fetchDashboardCompanyList = (params: FetchDashboardCompanyListParamsType) =>
  httpApi.get<DashboardCompanyType[]>({ url: '/dashboard/dscompany/list', queryParams: params })

export type FetchDashboardCompanyPieChartDatasParamsType = {
  branchCode: string
}
const fetchDashboardCompanyPieChartDatas = (params: FetchDashboardCompanyPieChartDatasParamsType) =>
  httpApi.get<DsCompanyPieChartDatasType>({ url: '/dashboard/dscompany/piechartdatas', queryParams: params })

export type FetchDashboardCompanyUnspecifiedIndustryParamsType = {
  branchCode: string
}
const fetchDashboardCompanyUnspecifiedIndustry = (params: FetchDashboardCompanyUnspecifiedIndustryParamsType) =>
  httpApi.get<DashboardCompanyUnspecifiedIndustry>({
    url: '/dashboard/dscompany/unspecifiedindustry',
    queryParams: params,
  })

export type FetchDashboardContractParamsType = {
  branchCode: string
  lookbackPeriod: string
}
const fetchDashboardContract = (params: FetchDashboardContractParamsType) =>
  httpApi.get<DashboardContractType[]>({ url: '/dashboard/dscontract', queryParams: params })

export type FetchDashboardIndustryParamsType = {
  industry1: string
  industry2: string
  industry3: string
  corporateType: string
}
const fetchDashboardIndustry = (params: FetchDashboardIndustryParamsType) =>
  httpApi.get<DashboardIndustryType>({ url: '/dashboard/dsindustry', queryParams: params })

export type CreateDashboardCompanyParamsType = {
  projectId: number
}
const createDashboardCompany = (params: CreateDashboardCompanyParamsType) =>
  httpApi.post({ url: `/dashboard/dscompany/${params.projectId}` })

const fetchDashboardCompanyDownload = () =>
  httpApi.get<DashboardCompanyDownloadType[]>({ url: `/dashboard/dscompany/dsdownload` })

export type CreateDashboardContractParamsType = {
  projectId: number
}
const createDashboardContract = (params: CreateDashboardContractParamsType) =>
  httpApi.post({ url: `/dashboard/dscontract/${params.projectId}` })

export type CreateDashboardIndustryParamsType = {
  projectId: number
}
const createDashboardIndustry = (params: CreateDashboardIndustryParamsType) =>
  httpApi.post({ url: `/dashboard/dsindustry/${params.projectId}` })

export type DownloadDsContractDocParamsType = {
  branchCode: string
  lookbackPeriod: string
  colorSet: { [name: string]: string }
}
const downloadDsContractDoc = (params: DownloadDsContractDocParamsType) =>
  httpApi.post({ url: '/dashboard/dscontract/doc', body: params })

export type DownloadDsIndustryDocParamsType = {
  industry1: string
  industry2: string
  industry3: string
  corporateType: string
}
const downloadDsIndustryDoc = (params: DownloadDsIndustryDocParamsType) =>
  httpApi.post({ url: '/dashboard/dsindustry/doc', body: params })

export type DownloadDsCompanyDocParamsType = {
  branchCodes: string[]
}
const downloadDsCompanyDoc = (params: DownloadDsCompanyDocParamsType) =>
  httpApi.post({ url: '/dashboard/dscompany/doc', body: params })

export type DownloadDsCompanyListParamsType = {
  branchCode: string
}
const downloadDsCompanyList = (params: DownloadDsCompanyListParamsType) =>
  httpApi.post({ url: '/dashboard/dscompany/csv', body: params })

export const dashboardService = {
  fetchDashboardContractFilterItems,
  fetchDashboardIndustryFilterCorporateType,
  fetchDashboardIndustryFilterIndustry1s,
  fetchDashboardIndustryFilterIndustry2s,
  fetchDashboardIndustryFilterIndustry3s,
  fetchDashboardCompanyFilterItems,

  fetchDashboardCompany,
  fetchDashboardCompanyList,
  fetchDashboardCompanyPieChartDatas,
  fetchDashboardCompanyUnspecifiedIndustry,

  fetchDashboardContract,

  fetchDashboardIndustry,

  createDashboardCompany,
  fetchDashboardCompanyDownload,

  createDashboardContract,
  createDashboardIndustry,

  downloadDsContractDoc,
  downloadDsIndustryDoc,
  downloadDsCompanyDoc,
  downloadDsCompanyList,
}
